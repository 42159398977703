import { z } from 'zod';

export enum Environments {
  Development = 'development',
  Production = 'production',
}

export enum FormNames {
  ADMIN_ADD_USER_TO_ORGANIZATION = 'adminAddUserToOrganization',
  ADMIN_REMOVE_USER_FROM_ORGANIZATION = 'adminRemoveUserFromOrganization',
  ADMIN_MARK_ORGANIZATION_TRUSTED = 'adminMarkOrganizationTrusted',
  ADMIN_DELETE_ORGANIZATION = 'adminDeleteOrganization',
  ADMIN_EDIT_RESERVATION = 'adminEditReservation',
  ADMIN_CREATE_RESERVATION_INVITATION = 'adminCreateReservationInvitation',
  ADMIN_UPDATE_RESERVATION_INVITATION = 'adminUpdateReservationInvitation',
  ADMIN_LINK_RESERVATION = 'adminLinkReservation',
  CONFIGURE_DEVICE_FORM = 'configureDevice',
  DIRECT_PROVISION_CONFIGURE_DEVICE_FORM = 'directProvisionConfigureDevice',
  DEPLOYMENT_REPROVISION_FORM = 'deploymentReprovision',
  DEPLOYMENT_DIRECT_REPROVISION_FORM = 'deploymentDirectReprovision',
  DEPLOYMENT_REPROVISION_CONFIRMATION_FORM = 'deploymentReprovisionConfirmation',
  DCIM_CREATE_RESERVATION_INVITATION = 'dcimCreateReservationInvitation',
  DCIM_UPDATE_RESERVATION_INVITATION = 'dcimUpdateReservationInvitation',
  DCIM_DIRECT_PROVISION_FORM = 'dcimDirectProvision',
  DCIM_PROVISION_FORM = 'dcimProvision',
  HELPDESK_FORM = 'helpDesk',
  SSH_KEY_FORM = 'sshKey',
  IS_LISTED_FORM = 'isListed',
  CHANGE_PRICE_GROUP_FORM = 'changePriceGroup',
  DEVICE_PRICE_FORM = 'devicePrice',
  BULK_PRICE_UPDATE_FORM = 'bulkPriceUpdate',
}

export const OperatingSystemSchema = z
  .enum(['ubuntu2004', 'ubuntu2204', 'ubuntu-20-04', 'ubuntu-22-04'])
  .nullable();

export type OperatingSystemEnum = z.infer<typeof OperatingSystemSchema>;

export const DeviceCategoryEnum = z.enum([
  '4090',
  '3090',
  'a10',
  'a100',
  'a40',
  'a4000',
  'a5000',
  'a6000',
  'gh200',
  'h100',
  'h200',
  'l40s',
  'mi250',
  'mi300x',
  'v100',
  'cpu',
]);

export type DeviceCategoryEnum = z.infer<typeof DeviceCategoryEnum>;

export enum DeviceStatus {
  Active = 'Active',
  Queued = 'Queued',
  Planned = 'Planned',
  Staged = 'Staged',
  Failed = 'Failed',
  Offline = 'Offline',
  Inventory = 'Inventory',
  Decommissioning = 'Decommissioning',
  Error = 'Error',
  Decommissioned = 'Decommissioned',
  Reprovisioning = 'Reprovisioning',
  Provisioning = 'Provisioning',
  Provisioned = 'Provisioned',
  Rebooting = 'Rebooting',
}

export enum ContractType {
  ON_DEMAND = 'ON_DEMAND',
  RESERVED_ROLLING = 'RESERVED_ROLLING',
  // INTERRUPTIBLE = 'INTERRUPTIBLE',
}

export enum ReservationChannel {
  HYDRA_MARKETPLACE = 'HYDRA_MARKETPLACE',
  HYDRA_SALES = 'HYDRA_SALES',
  DC_SALES = 'DC_SALES',
  SELF_PROVISION = 'SELF_PROVISION',
}

export enum BillingFrequency {
  HOURLY = 'HOURLY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum CollectionMethod {
  CHARGED_AUTOMATICALLY = 'CHARGED_AUTOMATICALLY',
  SEND_INVOICE = 'SEND_INVOICE',
}
